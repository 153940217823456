import React, { useCallback, useMemo } from 'react';
import { Breadcrumb, Button, Dropdown, Layout, Menu, theme } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Avatar from 'antd/lib/avatar/avatar';
import { titleize } from '../utils';

const { Header, Content, Sider, Footer } = Layout;

interface Props {
  children: React.ReactNode;
}

function PageLayout({ children }: Props) {
  const navigate = useNavigate();
  const auth = getAuth();
  const {
    token: { colorBgContainer, borderRadiusLG, colorPrimary, colorWhite },
  } = theme.useToken();

  const handleUserMenuClick = useCallback(
    ({ key: path }: { key: string }) => {
      switch (path) {
        case 'logout':
          auth.signOut();
          break;

        default:
          break;
      }
    },
    [auth],
  );

  const handleMainMenuClick = useCallback(
    ({ key: path }: { key: string }) => {
      navigate(path);
    },
    [navigate],
  );

  const userMenu = useMemo(
    () => (
      <Menu onClick={handleUserMenuClick}>
        <Menu.Item key="logout" danger icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    ),
    [handleUserMenuClick],
  );

  const photoURL = auth.currentUser?.photoURL ?? auth.currentUser?.providerData[0]?.photoURL;
  const displayName =
    auth.currentUser?.displayName ?? auth.currentUser?.providerData[0]?.displayName;

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          background: colorPrimary,
        }}
      >
        <Link to="/" style={{ color: colorWhite, fontSize: '18px', fontWeight: '600' }}>
          Earth.fm Firebase Admin
        </Link>
        <div style={{ flex: '1 1 0%' }} />
        <Dropdown overlay={userMenu} trigger={['hover']}>
          <Button style={{ display: 'flex', alignItems: 'center', height: '32px' }} type="text">
            <Avatar src={photoURL} size="small" />
            <span style={{ marginLeft: '10px', color: colorWhite }}>{displayName}</span>
          </Button>
        </Dropdown>
      </Header>
      <Layout>
        <Sider width={280} style={{ background: colorBgContainer }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            defaultOpenKeys={[window.location.pathname.split('/')[1]]}
            style={{ height: '100%', borderRight: 0 }}
            onClick={handleMainMenuClick}
          >
            <Menu.Item key="/users">Users</Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ minHeight: 'calc(100vh - 64px)', padding: '0 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {window.location.pathname.split('/').map((str) => (
              <Breadcrumb.Item key={str}>{titleize(str)}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>Developed with ❤️ on Earth</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PageLayout;
