// Inspired by 404 page in Ant Design Pro (https://preview.pro.ant.design/exception/404)
import { Button } from 'antd';
import styled from 'styled-components';
import illustration from '../images/error-404-illustration.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 24px;
`;

const ImageWrap = styled.div`
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 10%;
`;

const Illustration = styled.div`
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-image: url('${illustration}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
`;

const ContentWrap = styled.div`
  flex: auto;
`;

const Title = styled.h1`
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
`;

const Desc = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export default function Error404() {
  return (
    <Container>
      <ImageWrap>
        <Illustration />
      </ImageWrap>
      <ContentWrap>
        <Title>404</Title>
        <Desc>Sorry, the page you visited does not exist.</Desc>
        <div>
          <Button type="primary" href="/">
            Back to home
          </Button>
        </div>
      </ContentWrap>
    </Container>
  );
}
