import { useCallback } from 'react';
import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const Container = styled.div`
  display: flex;
  padding: max(20vh, 200px) 30px 60px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

interface Props {
  loading?: boolean;
}

export default function Login({ loading = false }: Props) {
  const handleLogin = useCallback(() => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  }, []);

  return (
    <Container>
      <Typography.Title>Earth.fm Firebase Admin</Typography.Title>
      <Button onClick={handleLogin} type="primary" loading={loading}>
        Login with Google
      </Button>
    </Container>
  );
}
