import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const isProduction = window.location.hostname === 'admin.earth.fm';

const firebaseConfig = isProduction
  ? {
      apiKey: 'AIzaSyA2W9c6Cj4conctZj6gR5E0yD-c2tcJNDI',
      authDomain: 'earthfm.firebaseapp.com',
      projectId: 'earthfm',
      storageBucket: 'earthfm.appspot.com',
      messagingSenderId: '570644835416',
      appId: '1:570644835416:web:1d4222fcf0fc69ba6c6e2d',
      measurementId: 'G-817PDFRS32',
    }
  : {
      apiKey: 'AIzaSyBM5SUFyM_AnvNOetgiNz84vobrvyP2tpA',
      authDomain: 'earthfm-staging.firebaseapp.com',
      projectId: 'earthfm-staging',
      storageBucket: 'earthfm-staging.appspot.com',
      messagingSenderId: '1080894999315',
      appId: '1:1080894999315:web:c308e0a650296576ba28e6',
      measurementId: 'G-HXLT5GK23Z',
    };

initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
}
