import { Form, message, Modal, Select } from 'antd';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AppUser } from '../type';
import { useState } from 'react';

const availableMemberships = [
  'individual_monthly',
  'individual_yearly',
  'family_monthly',
  'family_yearly',
];

interface Props {
  user: AppUser | null;
  onClose: () => void;
  onSave: (user: AppUser) => void;
}

export default function EditMembershipModal({ user, onClose, onSave }: Props) {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm<AppUser>();
  const handleSave = async () => {
    if (!user) {
      return;
    }

    setSaving(true);

    const functions = getFunctions();
    const setCustomClaim = httpsCallable<
      { uid: string; key: 'stripeRole' | 'admin'; value: string | undefined },
      { success: boolean }
    >(functions, 'setCustomClaim');

    try {
      await setCustomClaim({
        uid: user.uid,
        key: 'stripeRole',
        value: form.getFieldValue('membership') || undefined,
      });
      onSave({ ...user, membership: form.getFieldValue('membership') || null });
      message.success('Membership saved');
    } catch (error) {
      console.error(error);
      message.error('Failed to save membership');
    }

    setSaving(false);
  };

  return (
    <Modal
      title="Edit membership"
      open={!!user}
      onOk={handleSave}
      onCancel={onClose}
      okText="Save"
      okButtonProps={{ loading: saving }}
    >
      {user ? (
        <Form<AppUser> layout="vertical" form={form} initialValues={user}>
          <Form.Item label="Membership" name="membership">
            <Select
              options={[
                { label: 'Select a membership', value: '' },
                ...availableMemberships.map((membership) => ({
                  label: membership,
                  value: membership,
                })),
              ]}
            />
          </Form.Item>
        </Form>
      ) : null}
    </Modal>
  );
}
