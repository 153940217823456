import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Input,
  InputRef,
  message,
  Popconfirm,
  Space,
  Spin,
  Table,
  TableColumnType,
  Tag,
  theme,
  Tooltip,
  Divider,
} from 'antd';
import {
  UserOutlined,
  AppleFilled,
  GoogleOutlined,
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DateTime } from 'luxon';
import Highlighter from 'react-highlight-words';
import ButtonGroup from 'antd/es/button/button-group';
import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Line } from '@ant-design/plots';
import PageLayout from '../components/PageLayout';
import { AppUser } from '../type';
import EditMembershipModal from '../components/EditMembershipModal';

const authProviderIcons = {
  'google.com': <GoogleOutlined />,
  'apple.com': <AppleFilled />,
  password: <MailOutlined />,
};

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<AppUser[]>([]);
  const [togglingAdmin, setTogglingAdmin] = useState<string | null>(null);
  const [editMembership, setEditMembership] = useState<AppUser | null>(null);
  const [deletingUser, setDeletingUser] = useState<AppUser | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const functions = getFunctions();
    const getAllUsers = httpsCallable<unknown, { success: boolean; users: AppUser[] }>(
      functions,
      'getAllUsers',
    );

    try {
      const result = await getAllUsers();
      if (result.data.success && result.data.users) {
        setDataSource(result.data.users);
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to load users');
    }

    setLoading(false);
  }, []);

  const lineChartData = useMemo(() => {
    const newUsersMap = dataSource.reduce(
      (obj: { [membership: string]: { [date: string]: number } }, user: AppUser) => {
        const date = DateTime.fromHTTP(user.created).toISODate() ?? '';
        let membership = user.membership ?? 'free';

        if (!obj[membership]) {
          obj[membership] = {};
        }

        if (obj[membership][date]) {
          obj[membership][date] += 1;
        } else {
          obj[membership][date] = 1;
        }
        return obj;
      },
      {},
    );

    return Object.keys(newUsersMap)
      .reduce(
        (arr, membership) => [
          ...arr,
          ...Object.keys(newUsersMap[membership]).map((date) => ({
            date,
            membership,
            count: newUsersMap[membership][date],
          })),
        ],
        [] as Array<{ date: string; membership: string; count: number }>,
      )
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [dataSource]);

  useEffect(() => {
    loadUsers().catch((error) => {
      message.error('Failed to load users');
      console.error(error);
    });
  }, [loadUsers]);

  const toggleEditMembership = useCallback(
    (user: AppUser | null) => () => {
      setEditMembership(user);
    },
    [],
  );

  const handleMembershipSave = useCallback((user: AppUser) => {
    setDataSource((users) =>
      users.map((u) => (u.uid === user.uid ? { ...u, membership: user.membership } : u)),
    );
    setEditMembership(null);
  }, []);

  const deleteUser = useCallback(
    (user: AppUser) => async () => {
      setDeletingUser(user);
      const functions = getFunctions();
      const deleteUser = httpsCallable<{ uid: string }, { success: boolean }>(
        functions,
        'deleteUser',
      );
      try {
        await deleteUser({ uid: user.uid });
        setDataSource((users) => users.filter((u) => u.uid !== user.uid));
        message.success('User deleted successfully');
      } catch (error) {
        message.error('Failed to delete user');
        console.error(error);
      }
      setDeletingUser(null);
    },
    [],
  );

  const toggleAdmin = useCallback(
    (user: AppUser) => async () => {
      setTogglingAdmin(user.uid);
      const functions = getFunctions();
      const setCustomClaim = httpsCallable<
        { uid: string; key: 'stripeRole' | 'admin'; value: boolean | undefined },
        { success: boolean }
      >(functions, 'setCustomClaim');

      try {
        await setCustomClaim({
          uid: user.uid,
          key: 'admin',
          value: user.isAdmin ? undefined : true,
        });
        setDataSource((users) =>
          users.map((u) => (u.uid === user.uid ? { ...u, isAdmin: !u.isAdmin } : u)),
        );
        message.success(user.isAdmin ? 'Admin privileges removed' : 'Admin privileges granted');
      } catch (error) {
        message.error('Failed to update admin privileges');
        console.error(error);
      }

      setTogglingAdmin(null);
    },
    [],
  );

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: keyof AppUser): TableColumnType<AppUser> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? colorPrimary : undefined }} />
    ),
    onFilter: (value, user) =>
      user[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase()) ?? false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<AppUser> = [
    {
      title: 'Photo',
      dataIndex: 'photoURL',
      key: 'photoURL',
      render: (photoURL: string, user: AppUser) => (
        <Avatar src={photoURL} icon={<UserOutlined />} alt={user.name} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'User Type',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin: boolean, user: AppUser) => (
        <Tooltip
          title={
            isAdmin
              ? 'An admin has full access to this admin panel. They can view and edit all users and grant admin privileges to other users.'
              : null
          }
          mouseEnterDelay={0.5}
        >
          <Popconfirm
            title={isAdmin ? 'Remove admin privileges?' : 'Grant admin privileges?'}
            onConfirm={toggleAdmin(user)}
          >
            <Button
              type="link"
              loading={togglingAdmin === user.uid}
              disabled={deletingUser?.uid === user.uid}
            >
              <Tag color={isAdmin ? 'red' : 'cyan'}>{isAdmin ? 'Admin' : 'User'}</Tag>
            </Button>
          </Popconfirm>
        </Tooltip>
      ),
      filters: [{ text: 'Admin', value: true }],
      onFilter: (value, user) => user.isAdmin === value,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Membership',
      dataIndex: 'membership',
      key: 'membership',
      render: (membership: string, user: AppUser) => (
        <>
          <Tag color={membership ? 'green' : 'cyan'}>{membership ?? 'none'}</Tag>{' '}
          <Button
            type="text"
            onClick={toggleEditMembership(user)}
            disabled={deletingUser?.uid === user.uid}
            icon={<EditOutlined />}
          />
        </>
      ),
      filters: Array.from(
        new Set(dataSource.map((user) => user.membership).filter((membership) => !!membership)),
      )
        .map((membership) => ({ text: membership, value: membership as string }))
        .concat({
          text: 'none',
          value: 'none',
        }),
      onFilter: (value, user) => (value === 'none' ? !user.membership : user.membership === value),
    },
    {
      title: 'Auth Providers',
      dataIndex: 'authProviders',
      key: 'authProviders',
      render: (authProviders: ('password' | 'google.com' | 'apple.com')[]) =>
        authProviders.map((provider) => (
          <Tooltip key={provider} title={provider}>
            {authProviderIcons[provider] || <UserOutlined />}
          </Tooltip>
        )),
      filters: [
        { text: 'Google', value: 'google.com' },
        { text: 'Apple', value: 'apple.com' },
        { text: 'Password', value: 'password' },
      ],
      onFilter: (value, user) => user.authProviders.includes(value as string),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (created: string) => (
        <Tooltip title={created}>{DateTime.fromHTTP(created).toRelative()}</Tooltip>
      ),
      sorter: (a, b) =>
        DateTime.fromHTTP(a.created).toMillis() - DateTime.fromHTTP(b.created).toMillis(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Last Sign In',
      dataIndex: 'lastSignIn',
      key: 'lastSignIn',
      render: (lastSignIn: string) => (
        <Tooltip title={lastSignIn}>{DateTime.fromHTTP(lastSignIn).toRelative()}</Tooltip>
      ),
      sorter: (a, b) =>
        DateTime.fromHTTP(a.lastSignIn).toMillis() - DateTime.fromHTTP(b.lastSignIn).toMillis(),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // edit and delete buttons
      render: (actions: undefined, user: AppUser) => (
        <ButtonGroup>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={deleteUser(user)}
            >
              <Button loading={deletingUser?.uid === user.uid} danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <PageLayout>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
      <EditMembershipModal
        user={editMembership}
        onClose={toggleEditMembership(null)}
        onSave={handleMembershipSave}
      />
      <Divider />
      <Spin spinning={loading}>
        <Line
          data={lineChartData}
          xField="date"
          yField="count"
          seriesField="membership"
          padding="auto"
        />
      </Spin>
    </PageLayout>
  );
}
