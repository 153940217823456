import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'antd/dist/reset.css';
import Login from './pages/Login';
import Error404 from './pages/Error404';
import Error403 from './pages/Error403';
import Users from './pages/Users';

export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, (usr) => {
      setInitializing(false);
      setUser(usr);
      if (usr) {
        usr.getIdTokenResult().then((idTokenResult) => {
          setIsAdmin(!!idTokenResult.claims.admin);
        });
      }
    });
  }, []);

  if (!user) {
    return <Login loading={initializing} />;
  }

  if (!isAdmin) {
    return <Error403 />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/users" replace />} />
      <Route path="/users" element={<Users />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
